.product {
    margin: 0 auto;
    width: 300px;
    height: 465px;
    background: white;
    border-radius: 3px;
    position: relative;
    padding: 10px
}

.product img{
    margin: 0 auto;
    width: 100px;
    height: 265px;
}

.product:hover {
    border-color: #ff593d;
}

.product p {
    min-height: 25px;
}

.product span {
    font-weight: bold;
}

.product button {
    background-color: white;
    color: black;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 15px;
    padding: 5px;
}

.product button:hover {
    color: white;
    background-color: #ff593d;
    border: 1px solid #ff593d;
    cursor: pointer;
}

.product .image-product {
    /*height: 100%;*/
    width: 100%;
}